.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}
.dropdown-toggle {
    position: relative !important;
}
.dropdown-menu {
    position: absolute;
    z-index: 10000;
    display: none;
    padding: 10px 0px;
    font-size: 0.88rem;
    color: black;
    text-align: left;
    list-style: none;
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border-radius: 0.75rem;
    box-shadow: 0 3px 12px 0 rgb(0 0 0 / 0.15);
    height: fit-content;
    top: calc(-30%) !important;
    min-width: calc(100% + 7rem);
}
.homesearchdropdown .dropdown-menu {
    left: -80px !important;
    width: calc(100% + 5rem) !important;
}
.dropdowncontainer .dropdown-menu:after {
    left: 20px;
}
.dropdowncontainer_translated .dropdown-menu:after {
    right: 20px;
}
.dropdown-menu.show {
    animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in {
    0% {
        margin-top: -50px;
        visibility: hidden;
        opacity: 0;
    }
    100% {
        margin-top: 15px;
        visibility: visible;
        opacity: 1;
    }
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropdown-menu-left {
    right: auto;
    left: 0;
}
.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}
.dropdown-divider {
    height: 0;
    margin: 0.1rem 5px;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}
.dropdown-item {
    font-size: 16px;
    display: block;
    width: 100%;
    padding: 0.7rem 1.3rem;
    clear: both;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.726) !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    transition: 0.3s;
    text-decoration: none !important;
}
.dropdown-item:hover,
.dropdown-item:focus {
    text-decoration: none;
    background-color: #eeeeeeb2;
}
.dropdown-item:hover .link-hover {
    color: var(--secondary);
}
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: var(--secondary);
    pointer-events: none;
    background-color: transparent;
    cursor: not-allowed;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-header {
    display: block;
    padding: 0 1.5rem;
    margin-bottom: 0;
    font-size: 0.968rem;
    color: var(--primary);
    white-space: nowrap;
    border-bottom: 1px solid #eee;
    text-align: center;
    text-transform: capitalize;
}
.dropdown-item-text {
    display: block;
    padding: 0.4rem 1.5rem;
    color: var(--primary);
}
.btn-primary.dropdown-toggle {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-color: transparent;
    width: 100%;
}
.show > .btn-primary.dropdown-toggle {
    border-color: transparent;
    background-color: transparent;
}
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent;
    background-color: transparent;
}
.btn.dropdown-toggle:not(:disabled):not(.disabled):active,
.btn.dropdown-toggle:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: transparent;
}
.dropdown-container-translated .dropdown-item {
    text-align: right;
}
@media (max-width: 900px) {
    .dropdown-menu {
        width: 80%;
        left: 10%;
    }

    .dropdowncontainerrespar .dropdown-menu:after {
        right: 80% !important;
    }
    .dropdowncontainerresp .dropdown-menu:after {
        left: 80%;
    }
    .dropdowncontainerrespar .dropdown-menu:after {
        right: 80% !important;
    }
}
@media screen and (max-width: 450px) {
    .dropdown-menu {
        min-width: 10rem;
    }
}
